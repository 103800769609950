'use client'

import * as Sentry from '@sentry/nextjs'
import NextError from 'next/error'
import { useEffect } from 'react'

const GlobalError = ({
	error,
}: Readonly<{
	error: Error & { digest?: string }
}>) => {
	useEffect(() => {
		Sentry.captureException(error)
	}, [
		error,
	])

	/* `NextError` is the default Next.js error page component. Its type
        definition requires a `statusCode` prop. However, since the App Router
        does not expose status codes for errors, we simply pass 0 to render a
        generic error message. */

	return (
		<html lang="en">
			<body>
				<NextError statusCode={0} />
			</body>
		</html>
	)
}

export default GlobalError
